import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import { Container, Row, Col, Button } from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"

import styles from "../components/Project/project.module.css"

import ProjectWaterSection from "../components/Project/ProjectWaterSection"
import ProjectTransBlock from "../components/Project/ProjectTransBlock"
import ProjectTabs from "../components/Project/ProjectTabs"

const ProjectPage = ({data}) => (
    <Layout>
    <SEO title="The Teton Valley Aquatics Project" />
        <ProjectWaterSection>
            <ProjectTransBlock>
            <Row className="align-items-center">
              <Col md={6}>
                <h1 className={`${styles.projectWaterBlockHeadline} text-center text-md-left`}>
                  Teton Valley Aquatics Facility
                </h1>
                <p className={`${styles.waterBlockCopy} text-center text-md-left `}>
                The future Teton Valley Aquatics Facility will aim to provide year round water access to valley residents. We’re striving for a financially achievable approach to obtaining an aquatic facility that can grow with and adapt to changing needs. Read on for more information about where we are in the process towards Teton Valley's first community aquatics facility.
                </p>
                <Row className="text-center text-md-left">
                  <Col md={6} className="mb-4 text-center text-md-left">
                    <a href="https://assets.ctfassets.net/kjisl26qqle3/4IPp4eQEpeJ30CM2TyiN74/412e8d7802d6e770cf3899fd75bb36d1/2019-11-12_TVA_Feasibility_Executive_Summary.pdf" target="_blank" rel="noreferrer noopener" style={{textDecoration: 'none'}}><Button className={styles.letterButton}>TVA Facility Details</Button></a>
                  </Col>
                  <Col md={6} className="mb-4 text-center text-md-left">
                  {/* <Link to="/donate"style={{textDecoration: 'none'}}><Button className="donate-button" variant="">Donate Now</Button></Link> */}
                  <Link to="/donate" style={{textDecoration: 'none'}}><Button className="donate-button" variant="">Donate Now</Button></Link>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Img
                  fluid={data.aquaticsCenter.childImageSharp.fluid}
                  alt="Teton Valley Aquatics Center"
                  className="rounded"
                />
              </Col>
            </Row>
            </ProjectTransBlock>
        <Container>
            <ProjectTabs />
        </Container>
        </ProjectWaterSection> 
    </Layout>
)

export const data = graphql`
query {
  aquaticsCenter: file(relativePath: { eq: "project/aquatics-center.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 600, quality: 80) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`

export default ProjectPage;